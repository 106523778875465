(function($){
  $(function() {
    $('.js-password-show').on('click', function(e) {
      e.preventDefault();

      var $input = $(this).parent().find('input');
      if ($input.attr('type') == 'password') {
        $input.attr('type', 'text');
      }
      else {
        $input.attr('type', 'password');
      }
    });
  });
})(jQuery);
