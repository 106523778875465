(function($) {
  $(function() {
    var hash = window.location.hash.toString().replace('#', '');
    if (hash) {
      $('.js-letters').children().removeClass('c-definitions__letter--active');
      $('#side-' + hash).addClass('c-definitions__letter--active');
    }

    $('.js-letters').children().on('click', function() {
      $('.js-letters').children().removeClass('c-definitions__letter--active');
      $(this).addClass('c-definitions__letter--active');
    });
  });
})(jQuery);
